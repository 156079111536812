import React from 'react'
import { animated } from "react-spring"
import AwesomeSlider from 'react-awesome-slider';
import AwesomeSliderStyles from 'react-awesome-slider/src/styled/fold-out-animation';
import withAutoplay from 'react-awesome-slider/dist/autoplay';

const AutoplaySlider = withAutoplay(AwesomeSlider);

const ProjectItem = ({ node, style }) => {

  const interval = Math.floor(Math.random() * 999) + 4000;
  const fontColor = node.fontColor === null ? 'white' : node.fontColor;

  return (
    <animated.div key={node.id} className="item" style={style}>
      <div className="project-item">
        <AutoplaySlider
          animation="foldOutAnimation"
          play={true}
          cancelOnInteraction={false}
          interval={interval}
          name={`as-${node.slug}`}
          cssModule={AwesomeSliderStyles}
          // startupScreen={startupScreen}
          fillParent={true}
          organicArrows={false}
          bullets={false} buttons={false}>
          {
            node.images.map((image, index) => (
              <div key={index} data-src={`${image.src.publicURL}`}></div>
            ))
          }
        </AutoplaySlider>
        <div className="info" style={{ color: fontColor }}>
          <div
            style={{
              backgroundColor: node.color,
              height: `100%`,
              left: 0,
              position: `absolute`,
              top: 0,
              width: `100%`,
              zIndex: -2,
            }}
          />
          <h4>{node.name}</h4>
          <small>This design is available on various products at the following online stores: </small>
          {
            node.links.map(link => (
              <a key={link.name} style={{ color: fontColor }} href={link.url} className="productExternal">Shop on {link.name}</a>
            ))
          }

        </div>

      </div>
    </animated.div>
  )
}

export default ProjectItem;
