import React from "react"
import { graphql } from "gatsby"
import { useTrail } from "react-spring"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ProjectItem from "../components/project-item";

const IndexPage = ({ data }) => {
  const projects = data.allProductsJson.edges;

  console.log(projects)

  const config = { mass: 5, tension: 2000, friction: 200 }
  const trail = useTrail(projects.length, {
    config,
    from: { height: `0%` },
    to: { height: `100%` },
  })

  const items = trail.map((style, index) => (
    <ProjectItem key={index} node={projects[index].node} style={style} />
  ))

  return (
    <Layout>
      <SEO title="Home" />
      <main className="project-grid">
        {items}
      </main>
    </Layout>
  )
}

export const query = graphql`
  query MyQuery {
    allProductsJson(sort: {order: DESC, fields: id}) {
      edges {
        node {
          name
          slug
          color
          fontColor
          links {
            name
            url
          }
          images {
            src {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default IndexPage
